var $  = require( 'jquery' );
var dt = require('datatables.net-dt');
window.JSZip = require('jszip');
require('pdfmake');
require('datatables.net-buttons-dt' );
require('datatables.net-buttons/js/buttons.colVis.js' )();
require('datatables.net-buttons/js/dataTables.buttons.min')();
require('datatables.net-buttons/js/buttons.flash.min')();
require('datatables.net-buttons/js/buttons.html5.js' )();
var selectize = require('selectize');

$(document).on('turbolinks:load', () => {
  var columnsSales = [];
  var img64 = window.enterpriseLogo;
  var titleTable = "";
  var currentdate = new Date();
  var datetime = currentdate.getDate() + "/"
              + (currentdate.getMonth()+1)  + "/"
              + currentdate.getFullYear();
  var buttonCommon = {
    exportOptions: {
      format: {
        body: function (data, row, column, node) {
          if(column > 0) {
            node = $(node).find(".data-value");
            return node.is("input") ? node.val() : node.text().trim();
          }else{
            return row + 1;
          }
        }
      }
    }
  };

  if( $("#projects-sales").length ) {
    titleTable = "Ventas";
  }

  if( $("#has-building").text() == '1' ){
    columnsSales = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,16,17];
  }

  if( $("#has-building").text() == '0' ){
    columnsSales = [0,1,2,3,4,5,6,7,8,9,10,11,12,14,15];
  }
  
  let salesTable = $('#sales-table').DataTable({
    "language": {
      "lengthMenu": "Mostrar _MENU_ registros por página",
      "zeroRecords": "Nada encontrado",
      "info": "Mostrando página _PAGE_ de _PAGES_",
      "infoEmpty": "No hay registros disponibles",
      "infoFiltered": "(filtrado de _MAX_ registros)",
      "sSearch": "Buscar:",
      "oPaginate": {
        "sFirst": "Primero",
        "sPrevious": "Anterior",
        "sNext": "Siguiente",
        "sLast": "Último"
      }
    },
    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
    dom: 'Blftip',
    buttons: [
      $.extend( true, {}, buttonCommon, {
          extend: 'copyHtml5',
          footer: true,
          title: titleTable,
          messageTop: `Proyecto inmobiliario: ${$("#project-name").val()}.\n
                      Fecha de exportación: ${datetime}\n`,
          exportOptions: {
            columns: columnsSales,
          },
      } ),
      $.extend( true, {}, buttonCommon, {
          extend: 'excelHtml5',
          footer: true,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];
         
            $('row:eq(0) c', sheet).attr( 's', '50' );
            $('row:eq(1) c', sheet).attr( 's', '50' );
          },
          title: titleTable,
          messageTop: `Proyecto inmobiliario: ${$("#project-name").val()}.\n
                      Fecha de exportación: ${datetime}\n`,
          exportOptions: {
            columns: columnsSales,
          },
      } ),
      $.extend( true, {}, buttonCommon, {
          extend: 'pdfHtml5',
          orientation: 'landscape',
          pageSize: 'LEGAL',
          title: function() {
            return `${titleTable}`
          },
          messageTop: `Proyecto inmobiliario: ${$("#project-name").val()}.
                      Fecha de exportación: ${datetime}`,
          exportOptions: {
            columns: columnsSales,
            stripHtml: false,
            stripNewlines: false
          },
          customize: function ( doc ) {
            doc.content[0].style = {bold: true, alignment: "center", fontSize: "25"};

            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
      } )
    ]
  });

  $('#sales-table tbody').on( 'click', 'tr', function () {
    if ( $(this).hasClass('selected') ) {
        $(this).removeClass('selected');
    }
    else {
        salesTable.$('tr.selected').removeClass('selected');
        $(this).addClass('selected');
    }
  });


  $('.close-modal-sales-areas').click((e) => {
    e.preventDefault();
    hideVisibilityAnimation('.modal-sales-areas');
  });


  $('#sales-table tbody').on('click', 'tr', function () {
    var data = salesTable.row( this ).data();
    let positionColumnMarketingStage = 11;

    if ( $("#building").length && $("#level").length ) {
      positionColumnMarketingStage = 13;
    }

    $('#sale_seed_information_showed_id').val(data[0])
    $('#sale_update_seed_information_showed_id').val(data[0])
    $('#seed_information_ip_marketing_stage').val(data[positionColumnMarketingStage])
    $('#reservation_seed_information_showed_id').val(data[0])
    $('#cancellation_seed_information_showed_id').val(data[0])
  } );

  $('.show-modal-sell').click((e) => {
    e.preventDefault();
    $('.save-payment').hide();

    $('.total-sumatory-personalized-show').text('0.0');
    if ($('#sale_seed_information_showed_id').val() != '') {
      let select = $(document.getElementById('sale_ip_marketing_stage_id'));
      let selectize = select[0].selectize;
      
      let ip_marketing_stage_id = $('#seed_information_ip_marketing_stage').val();
      ip_marketing_stage_id = ip_marketing_stage_id.replace('<span class="hidden">', "");
      index_span = ip_marketing_stage_id.indexOf('</span>');
      id = ip_marketing_stage_id.substring(index_span, 0);
      selectize.setValue(id, false);

      showVisibilityAnimation($('#modal-sell'));
    }else{
      Swal.fire(
        'Alerta',
        'Seleccione una unidad.',
        'warning'
      )
    }
  });
  $('.show-modal-update').click((e) => {
    e.preventDefault();
    $('.save-payment').hide();

    $('.total-sumatory-personalized-show').text('0.0');
    if ($('#sale_seed_information_showed_id').val() != '') {

      $.ajax({
        url: `/sales/${$('#sale_seed_information_showed_id').val()}`,
        method: "GET",
        dataType: "json",
        data: {
          seed_id: $('#sale_seed_id').val()
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          if(response['error'] == true) {
            Swal.fire(
              'Alerta',
              'Seleccione una unidad vendida.',
              'warning'
            )
          }else{
            $('#sale_date_sale_update').val(response['date_sale']);
            let select = $(document.getElementById('sale_update_ip_marketing_stage_id'));
            let selectize = select[0].selectize;

            selectize.setValue(response['ip_marketing_stage_id'], false);
            
            let select1 = $(document.getElementById('sale_update_ip_financing_id'));
            let selectize1 = select1[0].selectize;

            selectize1.setValue(null);
            selectize1.clearOptions();
            response['ip_financings'].forEach(element => {
              selectize1.addOption({value: element[0]['$oid'], text: element[1]});
              selectize1.addItem(element[0]['$oid']);
            });

            selectize1.setValue(response['ip_financing_id'], false);

            showVisibilityAnimation($('#modal-update'));
          }
        }
      });

    }else{
      Swal.fire(
        'Alerta',
        'Seleccione una unidad.',
        'warning'
      )
    }
  });  

  $('.show-modal-separate').click((e) => {
    e.preventDefault();
    if ($('#reservation_seed_information_showed_id').val() != '') {
      showVisibilityAnimation($('#modal-separate'));
    }else{
      Swal.fire(
        'Alerta',
        'Seleccione una unidad.',
        'warning'
      )
    }
  });
  $('.show-modal-cancel').click((e) => {
    e.preventDefault();
    if ($('#cancellation_seed_information_showed_id').val() != '') {
      showVisibilityAnimation($('#modal-cancel'));
    }else{
      Swal.fire(
        'Alerta',
        'Seleccione una unidad.',
        'warning'
      )
    }
  });

  $('#update-sale-activated').change(function () {
    let bool = $('#sale_update_from_zero').val();
    
    var select = $("#sale_update_ip_marketing_stage_id").selectize();
    var selectize = select[0].selectize;

    var select1 = $("#sale_update_ip_financing_id").selectize();
    var selectize1 = select1[0].selectize;

    if(bool == 'false') {
      $('#sale_update_from_zero').val('true');
      selectize.enable();
      selectize1.enable();
    }else{
      $('#sale_update_from_zero').val('false');
      selectize.disable();
      selectize1.disable();
    }

  });

  $('#sale_ip_marketing_stage_id').change(function() {
    if($(this).val() == '') {
      return;
    }
    let seed_id = $('.seed_id').val();
    let ip_marketing_stage_id = $(this).val();
    let showed_id = $('#sale_seed_information_showed_id').val();

    $.ajax({
      url: `/sales/financings_by_marketing_stage`,
      method: "GET",
      dataType: "json",
      data: {
        seed_id: seed_id,
        ip_marketing_stage_id: ip_marketing_stage_id,
        showed_id: showed_id
      },
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        var select = $(document.getElementById('sale_ip_financing_id'));
        var selectize = select[0].selectize;
        selectize.setValue(null);
        selectize.clearOptions();
        response['ip_financings'].forEach(element => {
          selectize.addOption({value: element[0]['$oid'], text: element[1]});
          selectize.addItem(element[0]['$oid']);
        });
        selectize.addOption({value: 'personalizado', text: 'Personalizado'});
        selectize.addItem('personalizado');
        
        if(response['ip_financings_ids'].includes(response['ip_financing']['$oid'])) {
          selectize.setValue(response['ip_financing']['$oid'], false);
        }
      }
    });
  });

  $('#sale_update_ip_marketing_stage_id').change(function() {
    if($(this).val() == '') {
      return;
    }
    let seed_id = $('.seed_id').val();
    let ip_marketing_stage_id = $(this).val();
    let showed_id = $('#sale_seed_information_showed_id').val();

    $.ajax({
      url: `/sales/financings_by_marketing_stage`,
      method: "GET",
      dataType: "json",
      data: {
        seed_id: seed_id,
        ip_marketing_stage_id: ip_marketing_stage_id,
        showed_id: showed_id
      },
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        var select = $(document.getElementById('sale_update_ip_financing_id'));
        var selectize = select[0].selectize;
        selectize.setValue(null);
        selectize.clearOptions();
        response['ip_financings'].forEach(element => {
          selectize.addOption({value: element[0]['$oid'], text: element[1]});
          selectize.addItem(element[0]['$oid']);
        });
        selectize.addOption({value: 'personalizado', text: 'Personalizado'});
        selectize.addItem('personalizado');
        
        if (response['sale_financing_id'] != false) {
          selectize.setValue(response['sale_financing_id'], false);
        }
      }
    });
  });

  $('.select-financing-modal').click(function() {
    let existent = $('.existent-financing');
    let custom = $('.custom-financing');

    let seed_id = $('.seed_id').val();
    let showed_id = $('#sale_seed_information_showed_id').val();
    let date_sale = $('#sale_date_sale').val();

    let financingSelected = $('#sale_ip_financing_id').val();
    let marketingStageSelected = $('#sale_ip_marketing_stage_id').val();

    if(financingSelected != 'personalizado') {
      $.ajax({
        url: '/sales/financing',
        method: "GET",
        dataType: "json",
        data: {
          seed_id: seed_id,
          ip_financing_id: financingSelected,
          ip_marketing_stage_id: marketingStageSelected,
          date_sale: date_sale,
          showed_id: showed_id
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          $('#sale_date_sale_showed').val(date_sale);
          $('#sale_marketing_stage_showed').val($('#sale_ip_marketing_stage_id').text());
          $('#sale_financing_showed').val($('#sale_ip_financing_id').text());
          let sale_price_sale = parseFloat(response['price_sale'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
          let sale_price_m2 = parseFloat(response['price_m2'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
          $('#sale_price_sale').val(sale_price_sale);
          $('#sale_price_m2').val(sale_price_m2);
          
          $('.payments-by-sell').empty();
          $('.payments-by-sell').append(''+
            '<span class="text-gray-800 text-sm font-bold">Pagos</span>'+
          '');
          $('.guaranteed-rents-by-sell').empty();
          $('.guaranteed-rents-by-sell').append(''+
            '<span class="text-gray-800 text-sm font-bold">Rentas garantizadas</span>'+
          '');
          let initial_date = date_sale;
          initial_date = `${initial_date} 00:00:01`
          let initial_date_copy = new Date(initial_date);

          response['payment_months'].forEach(function(currentValue, index) {
            if(currentValue != null) {
              for( var [payment_type, payment_amount] of Object.entries(currentValue)){
                initial_date_copy = new Date(initial_date);

                  month = (( initial_date_copy.getMonth() + index - 1) + 1)
                  initial_month = initial_date_copy.getMonth() + 1

                  month = restartMonths(month)
                  calculateLastDayOfMonth(initial_date_copy, month)
                 
                  month_formatDate = restartMonthsDateFormat(initial_date_copy.getMonth(), index)
                  new_year = monthsToYears(initial_month, index, initial_date_copy.getFullYear())

                  initial_date_copy.setMonth(month_formatDate);
                  initial_date_copy.setFullYear(new_year)

                date_string =  (initial_date_copy.getDate()) + "/" + (initial_date_copy.getMonth() + 1) + "/" + initial_date_copy.getFullYear();

                payment_amount = parseFloat(payment_amount, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
                if(payment_type == 'Renta Garantizada') {
                  $('.guaranteed-rents-by-sell').append('' +
                  '<div class="container-payment ml-1 mb-1">'+
                    '<label for="price" class="block text-sm font-medium text-gray-700"> Mes '+ index +' | '+ date_string +' | <span class="concept">'+ payment_type +'</span></label>'+
                    '<div class="flex relative rounded-md shadow-sm">'+
                      '<input name="sale[guaranteed_months][]" class="hidden" value="'+ index +'" readonly>'+
                      '<input name="sale[guaranteed_types][]" class="hidden" value="'+ payment_type +'" readonly>'+
                      '<span> </span>'+
                      '<input name="sale[guaranteed_amounts][]" placeholder="$0.00" data-type="currency" type="text" onkeyup="changeSumatoryGuaranteed(this)" value="'+ payment_amount +'" class="payment-amount border border-gray-400 px-2 rounded-md text-sm text-gray-900 focus:outline-none focus:border-gray-800 w-full bg-white">'+
                    '</div>'+
                  '</div>'+
                  '');
                }else{
                  $('.payments-by-sell').append('' +
                  '<div class="container-payment ml-1 mb-1">'+
                    '<label for="price" class="block text-sm font-medium text-gray-700"> Mes '+ index +' | '+ date_string +' | <span class="concept">'+ payment_type +'</span></label>'+
                    '<div class="flex relative rounded-md shadow-sm">'+
                      '<input name="sale[payment_months][]" class="hidden" value="'+ index +'" readonly>'+
                      '<input name="sale[payment_types][]" class="hidden" value="'+ payment_type +'" readonly>'+
                      '<span></span>'+
                      '<input name="sale[payment_amounts][]" placeholder="$0.00" data-type="currency" type="text"  onkeyup="changeSumatoryPayment(this)" value="'+ payment_amount +'" class="payment-amount border border-gray-400 px-2 rounded-md text-sm text-gray-900 focus:outline-none focus:border-gray-800 w-full bg-white">'+
                    '</div>'+
                  '</div>'+
                  '');
                }
              };
            }
          });

          $('.sumatories').empty();
          for( var [payment_type, payment_amount] of Object.entries(response['sumatories'])) {
            payment_amount = parseFloat(payment_amount, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
            $('.sumatories').append('' +
              '<div class="flex mb-3">'+
                '<span class="mr-2 text-xs mt-1">'+ payment_type +':</span>'+
                '<label class="text-sm mr-1"></label>'+
                '<input type="text" placeholder="$0.00" pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" data={type: "currency"} value="'+ payment_amount +'" class="text-xs border border-gray-400 px-2 rounded-md text-sm text-gray-900 focus:outline-none focus:border-gray-800 bg-gray-200" disabled>'+
              '</div>'+
            '');
          };

          let total_sum = parseFloat(response['total_sum'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
          //let m2_priced = parseFloat(response['total_m2'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
          $('.total-sumatory').val(total_sum);
          $('#m2_priced').val(response['total_m2']);
          
          if(parseFloat($('.total-sumatory').val().split('$').join('').split(',').join('')) == parseFloat($('#sale_price_sale').val().split('$').join('').split(',').join(''))) {
            $('.save-payment').show()
          }else{
            $('.save-payment').hide();
          }

        }
      }); 
    }else{
      $.ajax({
        url: '/sales/financing_personalized',
        method: "GET",
        dataType: "json",
        data: {
          seed_id: seed_id,
          ip_financing_id: financingSelected,
          ip_marketing_stage_id: marketingStageSelected,
          date_sale: date_sale,
          showed_id: showed_id
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          $('#sale_date_sale_showed_personalized').val(date_sale);
          $('#sale_marketing_stage_showed_personalized').val($('#sale_ip_marketing_stage_id').text());
          $('#sale_financing_showed_personalized').val($('#sale_ip_financing_id').text());
          $('#sale_area_total_personalized').val(response['total_m2']);
          $('#sale_area_total_sold_personalized').val(response['total_m2_priced']);
          let sale_price_sale_personalized = parseFloat(response['price_sale'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
          let sale_price_m2_personalized = parseFloat(response['price_m2'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
          $('#sale_price_sale_personalized').val(sale_price_sale_personalized);
          $('#sale_price_m2_personalized').val(sale_price_m2_personalized);

          if(parseFloat($('.total-sumatory').val().split('$').join('').split(',').join('')) == parseFloat($('#sale_price_sale').val().split('$').join('').split(',').join(''))) {
            $('.save-payment').show()
          }else{
            $('.save-payment').hide();
          }
        }
      });
    }

    if(financingSelected == 'personalizado') {
      existent.hide();
      custom.show();
    }else {
      custom.hide();
      existent.show();
    }
  });

  $('.select-update-financing-modal').click(function() {
    let existent = $('.update-existent-financing');
    let custom = $('.update-custom-financing');

    let seed_id = $('.seed_id').val();
    let update_from_zero = $('#sale_update_from_zero').val();
    let showed_id = $('#sale_seed_information_showed_id').val();
    let date_sale = $('#sale_date_sale').val();

    let financingSelected = $('#sale_update_ip_financing_id').val();
    let marketingStageSelected = $('#sale_update_ip_marketing_stage_id').val();

    //UNTIL HERE EVERYTHING IS FINE
    if(financingSelected != 'personalizado') {
      $.ajax({
        url: '/sales/financing',
        method: "GET",
        dataType: "json",
        data: {
          seed_id: seed_id,
          ip_financing_id: financingSelected,
          ip_marketing_stage_id: marketingStageSelected,
          date_sale: date_sale,
          showed_id: showed_id,
          update_from_zero: update_from_zero
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          $('#sale_update_date_sale_showed').val(response['date_sale']);
          $('#sale_update_marketing_stage_showed').val(response['sale_marketing_stage']);
          $('#sale_update_financing_showed').val(response['sale_financing']);
          let sale_price_sale = parseFloat(response['price_sale'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
          let sale_price_m2 = parseFloat(response['price_m2'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
          $('#sale_update_price_sale').val(sale_price_sale);
          $('#sale_update_price_m2').val(sale_price_m2);
          $('#sale_update_day_payment').val(response['day_payment']);
          
          $('.payments-by-sell').empty();
          $('.payments-by-sell').append(''+
            '<span class="text-gray-800 text-sm font-bold">Pagos</span>'+
          '');
          $('.guaranteed-rents-by-sell').empty();
          $('.guaranteed-rents-by-sell').append(''+
            '<span class="text-gray-800 text-sm font-bold">Rentas garantizadas</span>'+
          '');
          let initial_date = response['date_sale'];
          initial_date = `${initial_date} 00:00:01`
          let initial_date_copy = new Date(initial_date);

          response['payment_months'].forEach(function(currentValue, index) {
            if(currentValue != null) {
              for( var [payment_type, payment_amount] of Object.entries(currentValue)){
                initial_date_copy = new Date(initial_date);

                month = (( initial_date_copy.getMonth() + index - 1) + 1)
                initial_month = initial_date_copy.getMonth() + 1

                month = restartMonths(month)
                calculateLastDayOfMonth(initial_date_copy, month)
               
                month_formatDate = restartMonthsDateFormat(initial_date_copy.getMonth(), index)
                new_year = monthsToYears(initial_month, index, initial_date_copy.getFullYear())

                initial_date_copy.setMonth(month_formatDate);
                initial_date_copy.setFullYear(new_year)

                date_string =  (initial_date_copy.getDate()) + "/" + (initial_date_copy.getMonth() + 1) + "/" + initial_date_copy.getFullYear();

                payment_amount = parseFloat(payment_amount, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
                if(payment_type == 'Renta Garantizada') {
                  $('.guaranteed-rents-by-sell').append('' +
                  '<div class="container-payment ml-1 mb-1">'+
                    '<label for="price" class="block text-sm font-medium text-gray-700"> Mes '+ index +' | '+ date_string +' | <span class="concept">'+ payment_type +'</span></label>'+
                    '<div class="flex relative rounded-md shadow-sm">'+
                      '<input name="sale[guaranteed_months][]" class="hidden" value="'+ index +'" readonly>'+
                      '<input name="sale[guaranteed_types][]" class="hidden" value="'+ payment_type +'" readonly>'+
                      '<span> </span>'+
                      '<input name="sale[guaranteed_amounts][]" placeholder="$0.00" data-type="currency" type="text" onkeyup="changeSumatoryGuaranteed(this)" value="'+ payment_amount +'" class="payment-amount border border-gray-400 px-2 rounded-md text-sm text-gray-900 focus:outline-none focus:border-gray-800 w-full bg-white">'+
                    '</div>'+
                  '</div>'+
                  '');
                }else{
                  $('.payments-by-sell').append('' +
                  '<div class="container-payment ml-1 mb-1">'+
                    '<label for="price" class="block text-sm font-medium text-gray-700"> Mes '+ index +' | '+ date_string +' | <span class="concept">'+ payment_type +'</span></label>'+
                    '<div class="flex relative rounded-md shadow-sm">'+
                      '<input name="sale[payment_months][]" class="hidden" value="'+ index +'" readonly>'+
                      '<input name="sale[payment_types][]" class="hidden" value="'+ payment_type +'" readonly>'+
                      '<span></span>'+
                      '<input name="sale[payment_amounts][]" placeholder="$0.00" data-type="currency" type="text"  onkeyup="changeSumatoryPayment(this)" value="'+ payment_amount +'" class="payment-amount border border-gray-400 px-2 rounded-md text-sm text-gray-900 focus:outline-none focus:border-gray-800 w-full bg-white">'+
                    '</div>'+
                  '</div>'+
                  '');
                }
              };
            }
          });

          $('.sumatories').empty();
          for( var [payment_type, payment_amount] of Object.entries(response['sumatories'])) {
            payment_amount = parseFloat(payment_amount, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
            $('.sumatories').append('' +
              '<div class="flex mb-3">'+
                '<span class="mr-2 text-xs mt-1">'+ payment_type +':</span>'+
                '<label class="text-sm mr-1"></label>'+
                '<input type="text" placeholder="$0.00" pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" data={type: "currency"} value="'+ payment_amount +'" class="text-xs border border-gray-400 px-2 rounded-md text-sm text-gray-900 focus:outline-none focus:border-gray-800 bg-gray-200" disabled>'+
              '</div>'+
            '');
          };

          let total_sum = parseFloat(response['total_sum'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
          //let m2_priced = parseFloat(response['total_m2'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
          $('.total-sumatory').val(total_sum);
          $('#update_m2_priced').val(response['total_m2']);
          
          if(parseFloat($('.total-sumatory').val().split('$').join('').split(',').join('')) == parseFloat($('#sale_update_price_sale').val().split('$').join('').split(',').join(''))) {
            $('.save-payment').show()
          }else{
            $('.save-payment').hide();
          }

        }
      }); 
    }else{
      if(update_from_zero == 'true') {
        $.ajax({
          url: '/sales/financing_personalized',
          method: "GET",
          dataType: "json",
          data: {
            seed_id: seed_id,
            ip_financing_id: financingSelected,
            ip_marketing_stage_id: marketingStageSelected,
            date_sale: date_sale,
            showed_id: showed_id
          },
          error: function (xhr, status, error) {
            console.error('AJAX Error: ' + status + error);
          },
          success: function (response) {
            $('#sale_update_date_sale_showed_personalized').val(response['date_sale']);
            $('#sale_update_marketing_stage_showed_personalized').val($('#sale_update_ip_marketing_stage_id').text());
            $('#sale_update_financing_showed_personalized').val('Personalizado');
            $('#sale_update_area_total_personalized').val(response['total_m2']);
            $('#sale_update_area_total_sold_personalized').val(response['total_m2_priced']);
            let sale_price_sale_personalized = parseFloat(response['price_sale'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
            let sale_price_m2_personalized = parseFloat(response['price_m2'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
            $('#sale_update_price_sale_personalized').val(sale_price_sale_personalized);
            $('#sale_update_price_m2_personalized').val(sale_price_m2_personalized);
  
            if(parseFloat($('.total-sumatory-personalized').val().split('$').join('').split(',').join('')) == parseFloat($('#sale_price_sale_personalized').val().split('$').join('').split(',').join(''))) {
              $('.save-payment').show()
            }else{
              $('.save-payment').hide();
            }
          }
        });
      }else{
        $.ajax({
          url: '/sales/financing_personalized',
          method: "GET",
          dataType: "json",
          data: {
            seed_id: seed_id,
            ip_financing_id: financingSelected,
            ip_marketing_stage_id: marketingStageSelected,
            date_sale: date_sale,
            showed_id: showed_id,
            update_from_zero: update_from_zero
          },
          error: function (xhr, status, error) {
            console.error('AJAX Error: ' + status + error);
          },
          success: function (response) {
            $('#sale_update_date_sale_showed').val(response['date_sale']);
            $('#sale_update_marketing_stage_showed').val(response['marketing_stage']);
            $('#sale_update_financing_showed').val(response['financing']);
            let sale_price_sale = parseFloat(response['price_sale'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
            let sale_price_m2 = parseFloat(response['price_m2'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
            $('#sale_update_price_sale').val(sale_price_sale);
            $('#sale_update_price_m2').val(sale_price_m2);
            $('#sale_update_day_payment').val(response['day_payment']);
            
            $('.payments-by-sell').empty();
            $('.payments-by-sell').append(''+
              '<span class="text-gray-800 text-sm font-bold">Pagos</span>'+
            '');
            $('.guaranteed-rents-by-sell').empty();
            $('.guaranteed-rents-by-sell').append(''+
              '<span class="text-gray-800 text-sm font-bold">Rentas garantizadas</span>'+
            '');
            let initial_date = response['date_sale'];
            initial_date = `${initial_date} 00:00:01`
            let initial_date_copy = new Date(initial_date);
            
            response['payment_months'].forEach(function(currentValue, index) {
              if(currentValue != null) {
                for( var [payment_type, payment_amount] of Object.entries(currentValue)){
                  initial_date_copy = new Date(initial_date);
                  
                  month = (( initial_date_copy.getMonth() + index - 1) + 1)
                  initial_month = initial_date_copy.getMonth() + 1

                  month = restartMonths(month)
                  calculateLastDayOfMonth(initial_date_copy, month)
                 
                  month_formatDate = restartMonthsDateFormat(initial_date_copy.getMonth(), index)
                  new_year = monthsToYears(initial_month, index, initial_date_copy.getFullYear())

                  initial_date_copy.setMonth(month_formatDate);
                  initial_date_copy.setFullYear(new_year)
                  
                  date_string =  (initial_date_copy.getDate()) + "/" + (initial_date_copy.getMonth() + 1) + "/" + initial_date_copy.getFullYear();
  
                  payment_amount = parseFloat(payment_amount, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
                  if(payment_type == 'Renta Garantizada') {
                    $('.guaranteed-rents-by-sell').append('' +
                    '<div class="container-payment ml-1 mb-1">'+
                      '<label for="price" class="block text-sm font-medium text-gray-700"> Mes '+ index +' | '+ date_string +' | <span class="concept">'+ payment_type +'</span></label>'+
                      '<div class="flex relative rounded-md shadow-sm">'+
                        '<input name="sale[guaranteed_months][]" class="hidden" value="'+ index +'" readonly>'+
                        '<input name="sale[guaranteed_types][]" class="hidden" value="'+ payment_type +'" readonly>'+
                        '<span> </span>'+
                        '<input name="sale[guaranteed_amounts][]" placeholder="$0.00" data-type="currency" type="text" onkeyup="changeSumatoryGuaranteed(this)" value="'+ payment_amount +'" class="payment-amount border border-gray-400 px-2 rounded-md text-sm text-gray-900 focus:outline-none focus:border-gray-800 w-full bg-white">'+
                      '</div>'+
                    '</div>'+
                    '');
                  }else{
                    $('.payments-by-sell').append('' +
                    '<div class="container-payment ml-1 mb-1">'+
                      '<label for="price" class="block text-sm font-medium text-gray-700"> Mes '+ index +' | '+ date_string +' | <span class="concept">'+ payment_type +'</span></label>'+
                      '<div class="flex relative rounded-md shadow-sm">'+
                        '<input name="sale[payment_months][]" class="hidden" value="'+ index +'" readonly>'+
                        '<input name="sale[payment_types][]" class="hidden" value="'+ payment_type +'" readonly>'+
                        '<span></span>'+
                        '<input name="sale[payment_amounts][]" placeholder="$0.00" data-type="currency" type="text"  onkeyup="changeSumatoryPayment(this)" value="'+ payment_amount +'" class="payment-amount border border-gray-400 px-2 rounded-md text-sm text-gray-900 focus:outline-none focus:border-gray-800 w-full bg-white">'+
                      '</div>'+
                    '</div>'+
                    '');
                  }
                };
              }
            });
  
            $('.sumatories').empty();
            for( var [payment_type, payment_amount] of Object.entries(response['sumatories'])) {
              payment_amount = parseFloat(payment_amount, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
              $('.sumatories').append('' +
                '<div class="flex mb-3">'+
                  '<span class="mr-2 text-xs mt-1">'+ payment_type +':</span>'+
                  '<label class="text-sm mr-1"></label>'+
                  '<input type="text" placeholder="$0.00" pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" data={type: "currency"} value="'+ payment_amount +'" class="text-xs border border-gray-400 px-2 rounded-md text-sm text-gray-900 focus:outline-none focus:border-gray-800 bg-gray-200" disabled>'+
                '</div>'+
              '');
            };
  
            let total_sum = parseFloat(response['total_sum'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
            //let m2_priced = parseFloat(response['total_m2'], 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
            $('.total-sumatory').val(total_sum);
            $('#update_m2_priced').val(response['total_m2_priced']);
            
            if(parseFloat($('.total-sumatory').val().split('$').join('').split(',').join('')) == parseFloat($('#sale_update_price_sale').val().split('$').join('').split(',').join(''))) {
              $('.save-payment').show()
            }else{
              $('.save-payment').hide();
            }
          }
        });
      }
    }

    if(financingSelected == 'personalizado' && update_from_zero == 'true') {
      existent.hide();
      custom.show();
    }else {
      custom.hide();
      existent.show();
    }
  });

  $('#sale_price_sale_personalized').keyup(function() {
    let price_m2_personalized = $('#sale_area_total_sold_personalized').val().split('$').join('').split(',').join('');
    let price_sale_personalized = $(this).val().split('$').join('').split(',').join('');

    let m2 = parseFloat(price_sale_personalized / price_m2_personalized);
    m2 = parseFloat(m2, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })

    $('#sale_price_m2_personalized').val(m2)
  });

  $('#sale_update_price_sale_personalized').keyup(function() {
    let price_m2_personalized = $('#sale_update_area_total_sold_personalized').val().split('$').join('').split(',').join('');
    let price_sale_personalized = $(this).val().split('$').join('').split(',').join('');

    let m2 = parseFloat(price_sale_personalized / price_m2_personalized);
    m2 = parseFloat(m2, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })

    $('#sale_update_price_m2_personalized').val(m2)
  });

  $('#sale_price_m2_personalized').keyup(function() {
    let area_personalized = $('#sale_area_total_sold_personalized').val().split('$').join('').split(',').join('');
    let price_m2_personalized = $(this).val().split('$').join('').split(',').join('');

    let price = parseFloat(price_m2_personalized * area_personalized);
    price = parseFloat(price, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })

    $('#sale_price_sale_personalized').val(price)
  });

  $('#sale_update_price_m2_personalized').keyup(function() {
    let area_personalized = $('#sale_update_area_total_sold_personalized').val().split('$').join('').split(',').join('');
    let price_m2_personalized = $(this).val().split('$').join('').split(',').join('');

    let price = parseFloat(price_m2_personalized * area_personalized);
    price = parseFloat(price, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })

    $('#sale_update_price_sale_personalized').val(price)
  });

  $('#sale_price_sale').keyup(function() {
    let total_sumatory = $('.total-sumatory').val().split('$').join('').split(',').join('');
    let sale_price_sale = $('#sale_price_sale').val().split('$').join('').split(',').join('');
    if(parseFloat(total_sumatory) == parseFloat(sale_price_sale)) {
      $('.save-payment').show()
    }else{
      $('.save-payment').hide();
    }

    let total_m2 = $('#m2_priced').val().split('$').join('').split(',').join('');
    let sale_price_sale_input = $(this).val().split('$').join('').split(',').join('');
    let price_by_m2 = parseFloat(sale_price_sale_input) / parseFloat(total_m2);
    price_by_m2 = parseFloat(price_by_m2, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })

    $('#sale_price_m2').val(price_by_m2);
  });

  $('#sale_update_price_sale').keyup(function() {
    let total_sumatory = $('.total-sumatory').val().split('$').join('').split(',').join('');
    let sale_price_sale = $('#sale_update_price_sale').val().split('$').join('').split(',').join('');
    if(parseFloat(total_sumatory) == parseFloat(sale_price_sale)) {
      $('.save-payment').show()
    }else{
      $('.save-payment').hide();
    }

    let total_m2 = $('#update_m2_priced').val().split('$').join('').split(',').join('');
    let sale_price_sale_input = $(this).val().split('$').join('').split(',').join('');
    let price_by_m2 = parseFloat(sale_price_sale_input) / parseFloat(total_m2);
    price_by_m2 = parseFloat(price_by_m2, 10).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })

    $('#sale_update_price_m2').val(price_by_m2);
  });

  $('.show-modal-guaranteed').click((e) => {
    e.preventDefault();
    showVisibilityAnimation($('#modal-guaranteed'));
  });

  $('#sale-button').click(function(){
    $("#pre-load").empty();
    $("#pre-load").append('<li class="border-gray-400 flex flex-row mb-2">' +
      '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
        '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
        '<div class="flex-1 pl-1 mr-16">' +
          '<div class="font-medium">No hay archivos seleccionados</div>' +
        '</div>' +
        '<div class="text-gray-600 text-xs"></div>' +
      '</div>' +
    '</li>');
  });
  
  $('#edit-button').click(function(){
    $("#edit-pre-load").empty();
    $("#edit-pre-load").append('<li class="border-gray-400 flex flex-row mb-2">' +
      '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
        '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
        '<div class="flex-1 pl-1 mr-16">' +
          '<div class="font-medium">No hay archivos seleccionados</div>' +
        '</div>' +
        '<div class="text-gray-600 text-xs"></div>' +
      '</div>' +
    '</li>');
  });

  $('.close-modal-sell').click((e) => {
    e.preventDefault();
    hideVisibilityAnimation('#modal-sell');

    let $select1 = $(document.getElementById('sale_client_id')).selectize();
    let selectize1 = $select1[0].selectize;
    selectize1.clear();

    let $select2 = $(document.getElementById('sale_ip_marketing_stage_id')).selectize();
    let selectize2 = $select2[0].selectize;
    selectize2.clear();

    let $select3 = $(document.getElementById('sale_ip_financing_id')).selectize();
    let selectize3 = $select3[0].selectize;
    selectize3.clear();

    $('#sale_commentary').val('');
  });

  $('.close-modal-update').click((e) => {
    e.preventDefault();
    hideVisibilityAnimation("#modal-update");
    $(".total-sumatory-personalized").val('0')
    $(".characteristics-payment-table").empty();
    $(".characteristics-guaranteed-table").empty();

    let $select2 = $(document.getElementById('sale_update_ip_marketing_stage_id')).selectize();
    let selectize2 = $select2[0].selectize;
    selectize2.clear();

    let $select3 = $(document.getElementById('sale_update_ip_financing_id')).selectize();
    let selectize3 = $select3[0].selectize;
    selectize3.clear();

    $('#sale_commentary').val('');
  });
  $('.close-modal-cash-prices').click((e) => {
    e.preventDefault();
    $('.modal-add-prices').hide();
  });
  $('.close-modal-separate').click((e) => {
    e.preventDefault();
    hideVisibilityAnimation('#modal-separate');
  });
  $('.close-modal-cancel').click((e) => {
    e.preventDefault();
    hideVisibilityAnimation('#modal-cancel');
  });
  $('.close-modal-guaranteed').click((e) => {
    e.preventDefault();
    hideVisibilityAnimation('#modal-guaranteed');
  });

  showVisibilityAnimation = (modalName) => {
    modalName.css("visibility", "visible").animate({ opacity: 1 });
  };

  hideVisibilityAnimation = (modalName) => {
    let modal = $(modalName);

    modal.css("visibility", "hidden");
    modal.css("opacity", "0");
  };

  const fileTempl = document.getElementById("file-template"),
    imageTempl = document.getElementById("image-template"),
    empty = document.getElementById("empty");
  
  // use to store pre selected files
  let FILES = {};
  
  // check if file is of type image and prepend the initialied
  // template to the target element
  function addFile(target, file) {
    const isImage = file.type.match("image.*"),
      objectURL = URL.createObjectURL(file);
  
    const clone = isImage
      ? imageTempl.content.cloneNode(true)
      : fileTempl.content.cloneNode(true);
  
    clone.querySelector("h1").textContent = file.name;
    clone.querySelector("li").id = objectURL;
    clone.querySelector(".delete").dataset.target = objectURL;
    clone.querySelector(".size").textContent =
      file.size > 1024
        ? file.size > 1048576
          ? Math.round(file.size / 1048576) + "mb"
          : Math.round(file.size / 1024) + "kb"
        : file.size + "b";
  
    isImage &&
      Object.assign(clone.querySelector("img"), {
        src: objectURL,
        alt: file.name
      });
  
    empty.classList.add("hidden");
    target.prepend(clone);
  
    FILES[objectURL] = file;
  }
  
  const gallery = $("#gallery"),
    overlay = $("#overlay");
  
  // click the hidden input of type file if the visible button is clicked
  // and capture the selected files
  const hidden = $("#hidden-input");
  $("#button").onclick = () => hidden.click();
  hidden.onchange = (e) => {
    for (const file of e.target.files) {
      addFile(gallery, file);
    }
  };
  
  // use to check if a file is being dragged
  const hasFiles = ({ dataTransfer: { types = [] } }) =>
    types.indexOf("Files") > -1;
  
  // use to drag dragenter and dragleave events.
  // this is to know if the outermost parent is dragged over
  // without issues due to drag events on its children
  let counter = 0;
  
  // reset counter and append file to gallery when file is dropped
  function dropHandler(ev) {
    ev.preventDefault();
    for (const file of ev.dataTransfer.files) {
      addFile(gallery, file);
      overlay.classList.remove("draggedover");
      counter = 0;
    }
  }
  
  // only react to actual files being dragged
  function dragEnterHandler(e) {
    e.preventDefault();
    if (!hasFiles(e)) {
      return;
    }
    ++counter && overlay.classList.add("draggedover");
  }
  
  function dragLeaveHandler(e) {
    1 > --counter && overlay.classList.remove("draggedover");
  }
  
  function dragOverHandler(e) {
    if (hasFiles(e)) {
      e.preventDefault();
    }
  }
  
  // event delegation to caputre delete events
  // fron the waste buckets in the file preview cards
  gallery.onclick = ({ target }) => {
    if (target.classList.contains("delete")) {
      const ou = target.dataset.target;
      document.getElementById(ou).remove(ou);
      gallery.children.length === 1 && empty.classList.remove("hidden");
      delete FILES[ou];
    }
  };
  
  // print all selected files
  $("#submit").onclick = () => {
    alert(`Submitted Files:\n${JSON.stringify(FILES)}`);
  };
  
  // clear entire selection
  $("#cancel").onclick = () => {
    while (gallery.children.length > 0) {
      gallery.lastChild.remove();
    }
    FILES = {};
    empty.classList.remove("hidden");
    gallery.append(empty);
  };

  const isLeap = (year) =>{
    return (year % 400 === 0) ? true : (year % 100 === 0) ? false : (year % 4 === 0);
  }
  function isThirtyOneDay(day) {
    return (day == 31) ? true : false;
  }

  function restartMonths(month) {
    if(month > 11){
      return month = (month % 12) 
    }else{
      return month
    }
  }

  function restartMonthsDateFormat(month,index) {
    if((month + index) > 12){
      month = ((month + index) % 12)
      return monthDateFormat = (month == 0) ? 11 : month - 1
    }else{
      return month = (month + index - 1)
    }
  }

  function monthsToYears(month, monthIndex, year){
    total_month = (month + monthIndex) - 1
    if(total_month > 12){
      old_year = parseInt(year)
      if((total_month % 12) == 0){
        return (old_year + (Math.trunc(total_month / 12) - 1))
      }else{
        return (old_year + Math.trunc(total_month / 12))
      }
    }else{
      return year
    }
  }

  function calculateLastDayOfMonth(initial_date_copy, month) {
    has_thirtyOne_day = isThirtyOneDay(initial_date_copy.getDate())
    year_leap = isLeap(initial_date_copy.getFullYear())

    switch (month) {
      case 2:
        if (year_leap) {
          if (has_thirtyOne_day) {
            initial_date_copy.setDate(initial_date_copy.getDate() - 2)            
            break;
          }
        } else {
          if (has_thirtyOne_day) {
            initial_date_copy.setDate(initial_date_copy.getDate() - 3)            
            break;
          }
        }
      case 4:
      case 6:
      case 9:
      case 11:
        if (has_thirtyOne_day) {
          initial_date_copy.setDate(initial_date_copy.getDate() - 1)
          break;
        }
      default: break;
    }
  }
  function textTypePercentage() {
    $('.title-type-amount').text('Porcentaje del precio total')
  }

  function textTypeAmoutn() {
    $('.title-type-amount').text('Monto del precio total')
  }

  $(".type-amount").on("change",function(){
    replyTypeAmount = $(this).val()
    if(replyTypeAmount == 0){
      textTypePercentage()
      $(".amount-payment").addClass('hidden');
      $(".percentage-payment").removeClass('hidden');
    }
    if(replyTypeAmount == 1){
      textTypeAmoutn()
      $(".percentage-payment").addClass('hidden');
      $(".amount-payment").removeClass('hidden');
    }
  })

  $('#addNewFiles').click(function () {
    var AUTH_TOKEN = $("meta[name=csrf-token]").attr("content");
    let formData = new FormData();
    let saleId = $('#sale_id').val();
    showLoading();
    formData.append('id',saleId)
    $.each($("#sale_upload_files"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        formData.append('files[' + i + ']', file);
      });
    });
    $("#sale_upload_files").val('');
    formData.append('authenticity_token', AUTH_TOKEN);
    $.ajax({
      url: '/sales/add_files',
      type: 'POST',
      data: formData,
      contentType: false,
      processData: false,
      success: function (data) {
        if (data['error'] == null) {
          Swal.fire(
            '¡Correcto!',
            'Los archivos se han añadido con éxito',
            'success'
          )
          let array_id_files = data['id_files']
          let files_information = data['files_information']
          $("#download-files").empty();
          array_id_files.forEach((element, index) => {
            $("#download-files").append('<li class="border-gray-200 flex flex-row mb-2">' +
              '<div class="select-none bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
                '<div class="delete-file flex flex-col rounded-md w-10 h-10 cursor-pointer justify-center items-center mr-4" onclick="deleteFile(this);">❌' +
                '<input class="sale-id hidden" type="text" value="'+ saleId +'">' +
                '<input class="file-id hidden" type="text" value="'+ element +'/'+ files_information[element] +'">' +
                '</div>' +
                '<div class="flex-1 pl-1 mr-16">' +
                  '<div class="font-medium">'+ files_information[element] +'</div>' +
                '</div>' +
                '<a href="https://devso.s3.amazonaws.com/uploads/consur/files/sales/'+ saleId +'/'+ element +'/'+ files_information[element] +'" download><svg class="w-8 h-8 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path></svg></a>' +
              '</div>' +
            '</li>');
          });
        } else {
          if (data['error'] == "error_file_max_size") {
            Swal.fire(
              'Verifique el tamaño del archivo',
              'Recuerde que solo puede subir hasta 10MB.',
              'warning'
            )
          }
          if (data['error'] == "error_no_files") {
            Swal.fire(
              'Seleccione uno o más archivos',
              'No se seleccionaron los archivos que desea subir',
              'warning'
            )
          }
        }
        $("#download-pre-load").empty();
        $("#download-pre-load").append('<li class="border-gray-400 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">No hay archivos seleccionados</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs"></div>' +
        '</div>' +
      '</li>');
      }
    });
  });

  $('#sale_files').change(function () {
    $("#pre-load").empty();
    $.each($("#sale_files"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        if (file.size > 20971520) {
          Swal.fire(
            'Verifique el tamaño de uno de los archivos',
            'Recuerde que solo puede subir hasta 20MB.',
            'warning'
          )
          $("#sale_files").val('')
          return
        }
        $("#pre-load").append('<li class="border-gray-200 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">'+ file.name +'</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs">'+ (file.size/1048576).toFixed(2) +' MB</div>' +
        '</div>' +
      '</li>');
      });
    });
    if ($("#sale_files").val() == '') {
      $("#pre-load").append('<li class="border-gray-200 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">No hay archivos seleccionados</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs"></div>' +
        '</div>' +
      '</li>');
    }
  });

  $('#sale_edit_files').change(function () {
    $("#edit-pre-load").empty();
    $.each($("#sale_edit_files"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        if (file.size > 20971520) {
          Swal.fire(
            'Verifique el tamaño de uno de los archivos',
            'Recuerde que solo puede subir hasta 20MB.',
            'warning'
          )
          $("#sale_edit_files").val('')
          return
        }
        $("#edit-pre-load").append('<li class="border-gray-200 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">'+ file.name +'</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs">'+ (file.size/1048576).toFixed(2) +' MB</div>' +
        '</div>' +
      '</li>');
      });
    });
    if ($("#sale_edit_files").val() == '') {
      $("#edit-pre-load").append('<li class="border-gray-200 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">No hay archivos seleccionados</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs"></div>' +
        '</div>' +
      '</li>');
    }
  });

  $('#sale_upload_files').change(function () {
    $("#download-pre-load").empty();
    $.each($("#sale_upload_files"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        if (file.size > 20971520) {
          Swal.fire(
            'Verifique el tamaño de uno de los archivos',
            'Recuerde que solo puede subir hasta 20MB.',
            'warning'
          )
          $("#sale_upload_files").val('')
          return
        }
        $("#download-pre-load").append('<li class="border-gray-400 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">'+ file.name +'</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs">'+ (file.size/1048576).toFixed(2) +' MB</div>' +
        '</div>' +
      '</li>');
      });
    });
    if ($("#sale_upload_files").val() == '') {
      $("#download-pre-load").append('<li class="border-gray-400 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">No hay archivos seleccionados</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs"></div>' +
        '</div>' +
      '</li>');
    }
  });

  function showLoading() {
    let timerInterval
    Swal.fire({
      title: 'Subiendo los archivos',
      html: 'Espera un momento, los archivos se están subiendo al servidor',
      didOpen: () => {
        Swal.showLoading()
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    })
  };
})

